
import { defineComponent, PropType } from 'vue';
import Card from '@/components/global/Card.vue';

export default defineComponent({
  components: { Card },
  props: {
    arabicTextInfo: String,
    arabicVerse: Array as PropType<Array<string>>,
    selectedWord: Number,
    makeLinks: Boolean,
  },
  emits: ['wordSelected'],
});
