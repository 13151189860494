import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "list-disc px-6 py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalysisItem = _resolveComponent("AnalysisItem")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: `Analysis ${_ctx.analysis.analysis}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.analysis, (value, key) => {
          return (_openBlock(), _createBlock(_component_AnalysisItem, {
            key: key + _ctx.$route.fullPath,
            name: key,
            value: value,
            fullAnalysis: _ctx.analysis
          }, null, 8, ["name", "value", "fullAnalysis"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}