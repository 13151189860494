import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-disc pl-6 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verses, (verse, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: "leading-6 py-1"
      }, [
        _createVNode(_component_router_link, {
          to: {
          name: 'VerseConcordance',
          params: {
            sura: verse.sura,
            verse: verse.verse,
            word: verse.word,
          },
        },
          innerHTML: verse.transcription_text[0]
        }, null, 8, ["to", "innerHTML"])
      ]))
    }), 128))
  ]))
}