import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "leading-10 align-middle"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallToggleButton = _resolveComponent("SmallToggleButton")!
  const _component_CorrespondingVerses = _resolveComponent("CorrespondingVerses")!

  return (_ctx.value !== null && _ctx.value !== '' && _ctx.name !== 'analysis' && _ctx.name !== 'word')
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        (Object.values(_ctx.WordType).includes(_ctx.name))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", {
                  class: _normalizeClass(`concordance-${_ctx.name}`)
                }, _toDisplayString(_ctx.$t('concordance.'+_ctx.name)) + ": " + _toDisplayString(_ctx.value), 3),
                (_ctx.occurrences)
                  ? (_openBlock(), _createBlock(_component_SmallToggleButton, {
                      key: 0,
                      text: `${_ctx.$t('concordance.occurrences')}: ` + _ctx.occurrences?.length,
                      open: _ctx.open,
                      class: "ml-4 align-middle",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
                    }, null, 8, ["text", "open"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_CorrespondingVerses, {
                verses: _ctx.formattedOccurrences,
                class: _normalizeClass([_ctx.occurrences && _ctx.open ? 'max-h-80' : 'max-h-0', "overflow-y-auto transition transition-maxHeight duration-700 bg-parchment"])
              }, null, 8, ["verses", "class"])
            ]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(`concordance-${_ctx.name}`)
            }, _toDisplayString(_ctx.$t('concordance.' + _ctx.name)) + ": " + _toDisplayString(_ctx.value), 3))
      ]))
    : _createCommentVNode("", true)
}