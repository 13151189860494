
import { defineComponent, PropType, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  props: {
    arabicVerse: Array as PropType<Array<string>>,
    transcription: Array as PropType<Array<string>>,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const selected = ref<number>(+route.params.word);

    watch([() => route.params.word], () => {
      selected.value = +route.params.word;
    });

    function navigate() {
      router.push({ params: { word: selected.value } });
    }

    return {
      selected,
      navigate,
    };
  },
});
