
import { defineComponent, PropType } from 'vue';
import Card from '@/components/global/Card.vue';
import AnalysisItem from '@/components/concordance/AnalysisItem.vue';
import { ConcordanceAnalyis } from '@/interfaces/ConcordanceAnalysis';

export default defineComponent({
  components: { Card, AnalysisItem },
  props: { analysis: Object as PropType<ConcordanceAnalyis> },
});
